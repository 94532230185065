import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceCategoriesByOrgId, fetchServicesbyOrgId } from '../../redux/actions/apiActions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Chip, Icon } from '@mui/material';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import ServiceCard from './ServiceCard';
import SkeletonCard from '../../components/Loaders/SkeletonCard';
import HorizontalItems from '../../components/HorizontalItems/HorizontalItems';
import SearchBar from '../../components/SearchBar/SearchBar';
import LocationFilterForExistingUsers from '../Organization/LocationFilterForExistingUser';
import { useMemo } from 'react';
import usePreviousValue from '../../hooks/usePreviousValue';
import { isEqual } from 'lodash';
import { setQueryParameters } from '../../redux/actions/commonActions';

const ServiceList = () => {
  const dispatch = useDispatch();
  const serviceList = useSelector(state => state.api.serviceList);
  const serviceListLoading = useSelector(state => state.api.serviceListLoading);
  const serviceCategories = useSelector(state => state.api.serviceCategories);
  const serviceCategoriesLoading = useSelector(state => state.api.serviceCategoriesLoading);
  const queryParams = useSelector(state => state.common.queryParams);

  const previousServiceList = usePreviousValue(serviceList);

  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();

  const [displayList, setDisplayList] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryId, setSelectedCategorId] = useState('all');
  const [alertMessage, setAlertMessage] = useState('');

  const { orgRef } = useParams();

  //State for location filter for exisitng customers 
  const [locationList, setLocationList] = useState(false);

  const checkLocationList = () => {
    setIsRefreshing(true);
    const locationSet = serviceList
      .filter(service => !service?.inactive)  // Filter active services
      .reduce((acc, service) => {
        service?.locations?.forEach(location => acc.set(location.id, location));
        return acc;
      }, new Map()).values();
    const locations = Array.from(locationSet);
    if (locations.length > 1) {
      //console.log("setting locations", locations)
      setLocationList(locations);
    } else {
      setLocationList([]);
    }
  }
  const onConfirmLocation = (closestLocations) => {
    //console.log("Location Confirmed, PIN available", closestLocations);
    if (closestLocations?.length) {
      const locArray = closestLocations.map(item => item.id);
      const filteredList = serviceList.filter(service => {
        if (service.inactive) return false; // Skip inactive services
        return locArray.some(loc => service?.locations?.map(item => item?.id).includes(loc));
      });
      //console.log("filteredList", filteredList)
      setDisplayList(filteredList);
      setSelectedCategorId("custom");
    } else {
      let sortedList = serviceList.sort((a, b) => a?.inactive - b?.inactive);
      setDisplayList(sortedList);
      setSelectedCategorId("all");
    }
  }

  const onClickCategory = (catId) => (e) => {
    setSelectedCategorId(catId);
    let sortedList = serviceList.sort((a, b) => a?.inactive - b?.inactive);
    if (catId !== 'all')
      sortedList = sortedList.filter(item => catId == item.serviceCategoryId);
    setDisplayList(sortedList);
  }

  const handleSearch = (searchText) => {
    if (searchText) {
      setSelectedCategorId("all")
      let filteredServiceList = serviceList.filter(service => service?.name?.toLowerCase()?.includes(searchText?.toLowerCase()));
      setDisplayList(filteredServiceList);
    } else {
      setDisplayList(serviceList.sort((a, b) => a?.inactive - b?.inactive));
    }
  }

  useEffect(() => {
    if (orgRef) {
      const queryParams_ = window?.location?.search;
      //console.log("queryParams_",queryParams_)
      dispatch(fetchServicesbyOrgId(orgRef, queryParams_));
      dispatch(fetchServiceCategoriesByOrgId(orgRef));
    }
  }, [orgRef])

  useEffect(() => {
    //console.log("ServiceList Updated",serviceList);
    if (!isEqual(serviceList, previousServiceList)) { //Trying to fix the glitch when reloading the page, but this approach is not working.
      //console.log("Previous ServiceList different ", previousServiceList, serviceList);
      if (serviceList?.length == 1) {
        navigate(`/p/service/${orgRef}/${serviceList[0]?.id}`);
      } else {
        checkLocationList();
        let sortedList = serviceList.sort((a, b) => a?.inactive - b?.inactive);
        if (queryParams?.branch) {
          console.log("queryParams", queryParams)
          sortedList = sortedList.filter(item => item?.locations?.map(item => item.id).includes(queryParams?.branch));
        }
        if (queryParams?.virtual == "true") {
          sortedList = sortedList.filter(item => item?.hasOnlineSchedules);
        }
        if (queryParams?.virtual == "false") {
          sortedList = sortedList.filter(item => item?.hasOfflineSchedules);
        }
        if (sortedList?.length == 1) {
          navigate(`/p/service/${orgRef}/${sortedList[0]?.id}`);
        }
        //console.log("Updating Display list", sortedList)
        setDisplayList(sortedList);
      }
    }
  }, [serviceList])

  useEffect(() => {
    //console.log("Mounted ServiceList Page");
    if (window?.location?.search) {
      const queryParams_ = Object.fromEntries(new URLSearchParams(window.location.search).entries());
      dispatch(setQueryParameters(queryParams_));
    }
  }, [])

  useEffect(() => {
    if (serviceCategories) {
      let catList = [{ iconName: 'checklist', label: 'All', value: 'all' }, ...serviceCategories];
      catList = catList.filter((item, idx) => catList.findIndex(elem => item.value == elem.value) === idx);
      catList = catList.sort((a, b) => (a?.position != null && b?.position != null ? (a.position - b.position) : 1));
      //console.log(catList)
      setCategoryList(catList);
    }
  }, [serviceCategories])

  useEffect(() => {
    if (displayList?.length) {
      setIsRefreshing(false); // Stop refreshing if displayList is populated
    } else if (!serviceListLoading) {
      setIsRefreshing(false); // Stop refreshing if serviceListLoading is false but no data is fetched
      setAlertMessage("No services available");
    }
  }, [displayList, serviceListLoading]);

  return (
    <Box className="p-2 min-h-full">
      <SearchBar
        containerProps={{ sx: { my: 2 } }}
        sx={{ mt: '5px' }}
        cardProps={{
          elevation: 5,
          sx: { borderRadius: 5, p: 1.5, px: 3, maxWidth: 'md', mx: 'auto' }
        }}
        textFieldProps={{
          placeholder: "Search for service",
          sx: { borderRadius: 5 },
        }}

        onChange={handleSearch}
      />
      <Box spacing={1} className="py-3 w-100 flex flex-row justify-center">
        <HorizontalItems>
          {
            serviceCategoriesLoading ?
              Array(parseInt(width / 150)).fill().map((item, idx) =>
                <SkeletonCard key={idx} cardType="categories" />)
              :
              categoryList.map(item =>
                <Chip
                  key={item.value}
                  icon={<Icon fontSize="small" >{item.iconName.replace(/-/, "_")}</Icon>}
                  label={item.label}
                  variant="outlined"
                  color={item.value == selectedCategoryId ? "primary" : "default"}
                  onClick={onClickCategory(item.value)}
                  sx={{ marginRight: 1 }}
                />
              )
          }
        </HorizontalItems>
      </Box>
      <GridContainer spacing={2} justifyContent="center">
        {serviceListLoading || isRefreshing ?
          <>
            {Array(parseInt(width / 300) * parseInt(height / 300)).fill().map((item, idx) =>
              <GridItem key={idx} xs={12} sm={12} md={6} lg={4} xl={3}>
                <Box sx={{ p: 1 }}>
                  <ServiceCard serviceData={null} isLoading={true} />
                </Box>
              </GridItem>)
            }
          </>
          : displayList.length > 0 ?
            displayList.map(serviceData =>
              <GridItem key={serviceData?.id} xs={12} sm={12} md={6} lg={4} xl={3}>
                <Box sx={{ p: 1 }}>
                  <ServiceCard serviceData={serviceData} isLoading={serviceListLoading} />
                </Box>
              </GridItem>
            )
            :
            <>
              {alertMessage &&
                <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 10 }} severity="error">{alertMessage}</Alert>
              }
            </>
        }
      </GridContainer>
      {(queryParams?.prompt == "true") &&
        <LocationFilterForExistingUsers
          locationList={locationList}
          onConfirmLocation={onConfirmLocation}
        />
      }
    </Box>
  )
}


export default ServiceList; 